// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");

// Google Font: Source Sans Pro
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700");

// Variables
@import "variables";

// Reset CSS
@import "./reset";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// AdminLTEのCSS読み込み
// fontawesome
@import "~admin-lte/plugins/fontawesome-free/css/all.min.css";
@import url("https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css");

// jquery-ui
@import "~admin-lte/plugins/jquery-ui/jquery-ui.min.css";

// icheck
@import "~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css";

// jquery datetimepicker
@import "~jquery-datetimepicker/build/jquery.datetimepicker.min.css";
@import "~jquery-timepicker/jquery.timepicker.css";

// toaster
@import "~admin-lte/plugins/toastr/toastr.css";

// datatable
@import "~admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css";
@import "~admin-lte/plugins/datatables-responsive/css/responsive.bootstrap4.min.css";

// fullcalendar
// なぜかこのCSSがない
// @import "~admin-lte/plugins/fullcalendar-interaction/main.min.css";
@import "~admin-lte/plugins/fullcalendar-daygrid/main.min.css";
@import "~admin-lte/plugins/fullcalendar-timegrid/main.min.css";
@import "~admin-lte/plugins/fullcalendar-bootstrap/main.min.css";

@import "~admin-lte/dist/css/adminlte.min.css";
// @import "~admin-lte/dist/css/skins/_all-skins.min.css";

// Githubのcss
@import "~github-markdown-css/github-markdown.css";

// vscodeのcssをhighlight.jsで利用
@import "~highlight.js/styles/github.css";

// Customize CSS
@import "./style";
