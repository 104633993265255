@import "./fonts";

@include font-face(
    "CloisterBlack",
    "/fonts/CloisterBlack.ttf",
    null,
    null,
    ttf
);

// 色を変数として設定
// 深い緑
$deep-green: #0e3838;
$green: #297171;
$gray: #464646;
$khaki: #847a60;
// 黒っぽい文字
$black: #323335;
// サイドメニューの背景、明るいベージュ
$light-beige: #d1c8af;

body {
    // ログインページの設定
    background-color: $deep-green;
    // navbar(上の固定メニュー)を固定したためマージンが必要
    margin-top: 70px;
    font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN",
        "Hiragino Sans", "Meiryo", sans-serif;

    &.login-page {
        background-image: url("/images/background.jpg");
        /* 画像ファイルの指定 */

        // ログインページはpaddin-top不要
        margin-top: 0;

        /* 画像を常に天地左右の中央に配置 */
        background-position: center center;

        /* 画像をタイル状に繰り返し表示しない */
        background-repeat: no-repeat;

        /* コンテンツの高さが画像の高さより大きい時、動かないように固定 */
        background-attachment: fixed;

        /* 表示するコンテナの大きさに基づいて、背景画像を調整 */
        background-size: cover;

        /* 背景画像が読み込まれる前に表示される背景のカラー */
        background-color: $gray;

        div {
            &.login-box {
                border: 0;
                width: 100%;

                // ログイン
                div {
                    &.card {
                        // border:5px solid brown;
                        background-image: url("/images/login.png");
                        // 背景色は透明
                        background-color: transparent;
                        background-size: 100% auto;

                        background-repeat: no-repeat;
                        border: 0;
                        box-shadow: none;

                        div {
                            &.card-body {
                                &.login-card-body {
                                    background-color: transparent;
                                    border: 0;
                                    p {
                                        // ログイン画面のLoginのテキスト
                                        &.login-box-msg {
                                            padding: 0;
                                        }
                                    }
                                    div {
                                        &.login-error-msg {
                                            height: 38px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

aside {
    &.main-sidebar {
        background-color: $light-beige;

        a {
            color: $black !important;

            &.brand-link {
                border-bottom: 1px solid $black;
            }
        }
    }

    // &.fixed-sidebar {
    //     position: sticky !important;
    // }
    div {
        &.sidebar {
            nav {
                background-color: #212529;

                div {
                    &.info {
                        a {
                            color: white;

                            &:hover {
                                opacity: 0.8;
                            }
                        }
                    }
                }
            }
        }
    }
}

// フッターのデザイン
footer {
    &.main-footer {
        background-color: $black;
        color: silver;
        border-top: 0;

        a {
            color: white;

            &:hover {
                // ホバーしたときの下の線を削除
                text-decoration: none;
                color: white;
                opacity: 0.8;
            }
        }
    }
}

// datatableの検索ボックス、件数表示の横幅
div {
    &.dataTables_wrapper {
        div {
            &.dataTables_filter {
                label {
                    width: 100%;

                    input {
                        width: 90%;
                    }
                }
            }

            &.dataTables_length {
                text-align: right;

                label {
                    select {
                        // 検索フィルタと合わせるために必要
                        margin-left: 0.5em;
                    }
                }
            }
        }
    }

    // マークダウンの本文
    &.markdown-body {
        img {
            width: 75%;
            max-width: 600px;
            display: block;
            margin: auto;
            object-fit: cover;

            &.slide{
                max-width: 1200px;
                width: 100%;
            }
            
        }

        div {
            &.youtube,
            &.google_drive {
                position: relative;
                width: 100%;
                max-width: 560px;
                margin: 20px auto;

                &::before {
                    content: "";
                    display: block;
                    width: 100%;
                    padding-top: 56.25%;
                }

                iframe {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    &.card-info {
        border: 0;
        margin-left: 5%;
        margin-right: 5%;
        border-radius: 0;

        &.card {
            div {
                &.card-header {
                    background-color: $green;
                    div {
                        &.progress-group {
                            color: white;
                            div {
                                &.progress-bar {
                                    background-color: #880000;
                                }
                            }
                        }
                    }
                }
            }

            div {
                &.memo-header {
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }
        }
    }

    &.card-footer {

        &.management-footer {
            margin: 2% 5%;
            background-color: transparent;
        }
    }

    &.progress-group {
        color: white;

        div {
            &.progress-bar {
                // クリア割合
                background-color: #880000;
            }
        }
    }

    // 標準サイズ
    &.content-wrapper {
        background-color: $deep-green;

        section {
            &.content-header {
                color: white;

                h1 {
                    margin: 20px;
                }

                button {
                    margin: 30px;
                }
            }
        }
    }

    &.dropdown-menu {
        background-color: $khaki;

        a {
            &:hover {
                opacity: 0.8;
            }

            &.dropdown-item {
                color: white;

                &:hover {
                    background-color: $khaki;
                    opacity: 0.8;
                }
            }
        }
    }
}

input {
    // チェックボックスの色変更

    &:checked + label::before {
        background-color: green !important;
        border-color: white !important;
    }

    & + label::before,
    &:hover + label::before {
        border-color: white !important;
    }
}

// プレビュー画面の編集

#markdown_edit,
#html_preview {
    height: 1500px !important;
    overflow-y: scroll;
}

table {
    th {
        &.schedule-user-name,
        &.schedule-user-part-user {
            min-width: 180px;
        }

        &.select-schedule-user-status {
            min-width: 160px !important;
        }

        &.schedule-user-log {
            min-width: 180px;
        }

        &.order-number {
            width: 80px;
        }

        &.update-checkbox {
            width: 80px;
        }

        &.upload-image {
            width: 320px;
        }

        &.goal {
            min-width: 200px;
        }

        &.item {
            width: 150px;
        }
    }

    tr {
        &.clickable {
            cursor: pointer;
        }

        &.unactive {
            background-color: whitesmoke;
        }

        span {
            &.input-add,
            &.input-del {
                cursor: pointer;
            }
            &.input-checkbox {
                background-color: transparent;
                border: 0;
            }
        }
    }

    img {
        &.view_img {
            width: 300px;
        }
    }
}

// ボタンの装飾
button {
    &.btn-secondary,
    &.btn-dark {
        margin: 10px;
        width: 200px;
    }

    // 完了ボタン
    &.complete {
        width: 500px;
        max-width: 70%;
    }

    // ログインボタン
    &.login-btn,
    &.codeland-btn {
        background-color: $green;
        border: 1px solid $black;
        color: white;
        margin: 10px;
        width: 200px;

        &:hover,
        &:active,
        &:focus,
        &:focus-within {
            background-color: $green !important;
            color: white !important;
            opacity: 0.8 !important;
        }

        &.disabled {
            background-color: lightgray;
            border: 0;

            &:hover,
            &:active,
            &:focus,
            &:focus-within {
                background-color: lightgray !important;
            }
        }
    }

    &.codeland-page-btn {
        background-color: $black;
        border: none;
        border-radius: 30px;
        color: white;
        margin: 10px;
        padding: 0;
        width: 55px;
        height: 30px;
        font-size: 0.8rem;

        &:hover,
        &:active,
        &:focus,
        &:focus-within {
            background-color: $black !important;
            color: white !important;
            opacity: 0.7 !important;
        }

        &.disabled {
            background-color: lightgray;

            &:hover,
            &:active,
            &:focus,
            &:focus-within {
                background-color: lightgray !important;
                color: white !important;
            }
        }

        i {
            font-size: 15px;
        }
    }
}

ul {
    margin: 1em 0;
    padding-left: 40px;
    list-style-type: disc;
}

ol {
    margin: 1em 0;
    padding-left: 40px;
    list-style-type: decimal;
}

// 左メニュー
li {
    // 左メニュー
    &.nav-item {
        i {
            width: 25px;
            text-align: center;
        }
    }

    &.breadcrumb-item {
        a {
            color: white;
        }
    }
}

nav {
    &.navbar-code-land {
        background-color: $khaki;
        border-bottom: 0;
    }

    a {
        &.nav-link {
            color: white !important;
        }
    }
}

// 警告メッセージ
.alert {
    ul {
        margin-top: 0;
        margin-bottom: 0;
    }
    li,
    ol,
    p {
        // 下の隙間をなくす
        margin-bottom: 0;
    }
}

.fc-ltr {
    .fc-dayGrid-view {
        .fc-day-top {
            .fc-day-number {
                font-size: 14px;
            }
        }
    }
}

span {
    &.fc-title {
        // カレンダーの日程を白文字

        color: white;
    }

    &.fc-time {
        // カレンダーの日程を白文字
        color: white;
    }
}

a {
    &.login-password-reset {
        color: $green;
    }
}

div {
    &.login-logo {
        img {
            width: 100px;
        }

        span {
            margin-left: 35px;
        }
    }
}

// 画面が大きい場合のログイン画面
.login-page {
    main {
        width: 480px;

        span {
            &.brand-text {
                // ログインタイトル
                font-family: CloisterBlack;

                &.title {
                    margin-left: 0;
                    font-size: 50px;
                    color: white;
                }

                &.login-title {
                    font-size: 30px;
                }
            }
        }

        div {
            &.card {
                margin: 0;
                padding: 80px 50px;
                width: 480px;
                height: 480px;

                div {
                    &.card-header {
                        // タイトルの背景色を削除
                        background-color: transparent;
                    }
                }
            }
        }
    }
}

// コースのテキストブック一覧ページのデザイン共通
div {

    &.course-title{
        margin-left: 5%;
        margin-right: 5%;

        h2{
            margin-top: 20px;
            margin-bottom: 20px;
            color:white;
            font-size:1.5rem;
        }
    }

    &.card {
        // コースの折りたたみをしている箇所全部
        &.card-course {
            background-color: transparent;
            box-shadow: none;

            div {
                // コースのヘッダー
                &.card-header {
                    background-color: transparent;
                    border: none;
                    cursor: pointer;

                    div {
                        margin-top: 5px;
                        margin-bottom: 5px;

                        &.course-title {
                            h2 {
                                color: white;
                                display: inline-block;
                                margin: auto auto;
                                font-size: 1.5rem;
                                line-height: 1.5rem;
                                font-weight: bold;
                            }
                        }

                        &.course-collapse {
                            margin: auto;

                            &.detail-open {
                                transform: rotate(180deg);
                                transition-duration: 0.5s;
                            }

                            &.detail-close {
                                transform: rotate(0);
                                transition-duration: 0.5s;
                            }
                        }

                    }
                }

                &.card-body {
                    background-color: #e4e3e3;
                    a {
                        text-decoration: none;
                    }
                    div {
                        &.card {
                            background-size: cover;
                            background-repeat: no-repeat;
                            background-color: transparent;
                            /* opacity:0.5; */
                            border: none;
                            box-shadow: none;
                            filter: drop-shadow(
                                0px 4px 10px rgba(50, 51, 53, 0.6)
                            );
                            &:hover{
                                transform:scale(1.05,1.05);
                                transition-duration: 0.5s;
                            }
                            // 取得していないコースの背景色は黒
                            &.locked::before {
                                /* 透過した黒を上から重ねるイメージ */
                                background-color: rgba(0, 0, 0, 0.4);
                                /* 自由に位置指定 */
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                content: " ";
                            }

                            // 取得していないコースの背景色は黒
                            &.completed::before {
                                /* 透過した黒を上から重ねるイメージ */
                                background-color: rgba(0, 0, 0, 0.2);
                                /* 自由に位置指定 */
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                content: " ";
                            }

                            div {
                                // 第何回の箇所
                                &.card-header {
                                    background-color: transparent;
                                    border: none;
                                    // text-align: center;
                                    color: white;
                                    border: 0;

                                    h3 {
                                        &.card-title {
                                            font-size: 1.25rem;
                                            font-weight: bold;
                                            width: 100%;
                                            margin: 0 auto;
                                            padding: 6px;
                                            line-height: 25px;
                                            box-shadow: 0px 2px 4px
                                                rgba(0, 0, 0, 0.2);
                                            img {
                                                height:15px;
                                                width: 15px;
                                                margin-bottom: 3px;
                                            }
                                            &.locked {
                                                background-color: darkgray;
                                                opacity: 0.5;
                                            }
                                        }
                                    }
                                }

                                // テキストのタイトル
                                &.card-body {
                                    font-size: 1.2rem;
                                    color: white;
                                    background-color: transparent;
                                }

                                &.card-footer {
                                    background-color: transparent;
                                    border: none;
                                    font-size: 30px;
                                    padding: 0px;

                                    div {
                                        // テキストブックが完了の場合
                                        &.completed {
                                            color: red;
                                            font-size: 20px;
                                            font-weight: bold;
                                        }

                                        // ロックされている場合
                                        &.locked {
                                            font-size: 20px;
                                            color: white;

                                            i {
                                                color: white;
                                            }
                                        }
                                    }

                                    // 下の管理メニュー
                                    &.management-footer {
                                        background-color: transparent;
                                        border: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        // textbook/showの表示画面
        &.card-textbook {
            background-color: transparent;
            box-shadow: none;

            div {
                &.card-body {
                    div {
                        &.textbook-body {
                            color: white;
                        }

                        &.textbook-progress {
                            color: white;
                        }

                        &.card {
                            background-size: cover;
                            background-repeat: no-repeat;
                            background-color: transparent;
                            /* opacity:0.5; */
                            border: none;
                            box-shadow: none;

                            div {
                                // 第何回の箇所
                                &.card-header {
                                    background-color: transparent;
                                    border: none;
                                    // text-align: center;
                                    color: white;
                                    border: none;

                                    h3 {
                                        &.card-title {
                                            // font-size: 1.25rem;
                                            font-weight: bold;
                                            width: 100%;
                                            margin: 0 auto;
                                        }
                                    }
                                }

                                // テキストのタイトル
                                &.card-body {
                                    font-size: 1.2rem;
                                    color: white;
                                }

                                &.card-footer {
                                    background-color: transparent;
                                    border: none;
                                    font-size: 30px;
                                    padding: 0px;
                                }
                            }
                        }
                    }

                    // 左側のテキストブックタイトル
                    h2 {
                        &.textbook-title {
                            font-size: 2.25rem;
                            color: white;
                            font-weight: bold;
                        }
                    }

                    h3 {
                        // コース名
                        &.course-title {
                            width: 180px;
                            height: 30px;
                            border: 1px solid $black;
                            color: white;
                            font-size: 0.8rem;
                            font-weight: bold;
                            line-height: 30px;
                            vertical-align: middle;
                            border-radius: 5px;
                        }
                    }
                }
            }
        }

        &.card-textbook-part {
            background-image: url("/images/background/part.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
            padding: 5%;

            div {
                &.card-header {
                    background-color: transparent;
                    border: none;
                    text-align: center;
                    color: white;
                    border: none;

                    h3 {
                        &.card-title {
                            font-family: CloisterBlack;
                            font-size: 2rem;
                            font-weight: bold;
                            width: 100%;
                            margin: 0 auto;
                            color: black;
                        }
                    }
                }

                &.card-body {
                    font-size: 1rem;

                    i {
                        color: $green;
                    }

                    a {
                        color: black;

                        span {
                            &.order_number {
                                display: inline-block;
                                width: 35px;
                            }
                        }
                    }
                }

                &.card-footer {
                    background-color: transparent;
                    border: 0;
                }
            }
        }

        // /parts
        &.card-part,
        &.card-textbook-part-question,
        &.card-textbook-part-answer{
            div{
                &.part-footer{
                    border-radius: 0;
                    background-color: $light-beige;
                    padding: 0;
                    
                    button{
                        max-width: 200px;
                        width: 60%;
                        height: 35px;
                        i{
                            font-size:0.8rem;
                            margin-left:10px;
                            margin-right:10px;
                        }
                    }
                }
            }
        }

        // 状態の一括更新画面
        &.card-part-status {
            background-color: transparent;
            box-shadow: none;
            cursor: pointer;
            div {
                // コースのヘッダー
                &.card-header {
                    background-color: transparent;
                    border: none;
                    div {
                        // margin-top: 5px;
                        // margin-bottom: 5px;
                        &.title {
                            // コースタイトル
                            h2 {
                                color: white;
                                margin: auto auto;
                                font-size: 1.5rem;
                                line-height: 1.5rem;
                                font-weight: bold;
                                margin-top: 5px;
                                margin-bottom: 20px;
                            }
                            // パートタイトル
                            h3 {
                                color: white;
                                margin: auto auto;
                                font-size: 1.2rem;
                                line-height: 1.2rem;
                                font-weight: bold;
                                margin-top: 15px;
                                margin-bottom: 5px;
                            }
                        }
                        &.course-collapse {
                            margin: auto;
                            &.detail-open {
                                transform: rotate(180deg);
                                transition-duration: 0.5s;
                            }
                            &.detail-close {
                                transform: rotate(0);
                                transition-duration: 0.5s;
                            }
                        }
                    }
                }
                &.card-body {
                    background-color: white;
                }
            }
        }
        // 質問の画面
        &.card-textbook-part-question,
        // 回答の画面
        &.card-textbook-part-answer {
            h3 {
                &.title-textbook-part-question,
                &.title-textbook-part-answer {
                    font-weight: bold;
                }
            }
            div {
                // 選択肢のデザイン
                &.div-textbook-part-question {
                    margin: 10px;
                    input {
                        cursor: pointer;
                    }
                    // 選択肢のラベルのデザイン
                    label {
                        font-size: 1rem;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

// ログイン画面
// 画面が大きい場合
@media (min-width: 577px) {
    .login-page {
        main {
            width: 480px;

            span {
                &.brand-text {
                    // ログインタイトル
                    font-family: CloisterBlack;

                    &.title {
                        margin-left: 0;
                        font-size: 50px;
                        color: white;
                    }

                    &.login-title {
                        font-size: 30px;
                    }
                }
            }

            div {
                &.card {
                    margin: 0;
                    padding: 50px 50px;
                    width: 450px;
                    height: 600px;

                    div {
                        &.login-card-body {
                            div {
                                &.login-form {
                                    margin-top: 60px;
                                }
                                // ログイン画面に限ってはcontainerのpaddingは0
                                &.container {
                                    padding: 0;
                                }
                            }
                        }
                    }

                    button {
                        &.login-btn {
                            width: 120px;
                        }
                    }
                }
            }
        }
    }
}

// ログイン画面
// 画面外小さい
@media (max-width: 576px) {
    .login-page {
        main {
            width: 350px;

            // ロゴの文字サイズ
            span {
                // ログインタイトル
                &.brand-text {
                    font-family: CloisterBlack;

                    &.title {
                        margin-left: 0;
                        font-size: 30px;
                        color: white;
                    }

                    &.login-title {
                        font-size: 30px;
                    }
                }
            }

            div {
                &.card {
                    margin: 0;
                    padding: 10px 10px;
                    width: 350px;
                    height: 470px;

                    div {
                        &.login-card-body {
                            div {
                                &.login-form {
                                    margin-top: 30px;
                                }
                            }
                        }
                    }

                    button {
                        &.login-btn {
                            width: 120px;
                        }
                    }
                }
            }

            .login-box,
            .register-box {
                width: 100%;
            }
        }

        table {
            img {
                &.view_img {
                    width: 150px;
                }
            }

            th {
                &.upload-image {
                    width: 180px;
                }
            }
        }

        div {
            &.content-wrapper {
                background-color: $deep-green;

                section {
                    &.content-header {
                        color: white;

                        h1 {
                            margin: 10px;
                        }

                        button {
                            margin: 15px;
                        }
                    }
                }
            }
        }
    }
}

// コース一覧画面の画面
// 画面が大きい場合、カードのデザインを変更

@media (min-width: 1200px) {
    // テキストブック一覧画面のデザイン
    div {
        &.card {
            // コース一覧画面
            &.card-course {
                // コースのタイトル
                div {
                    &.card-header {
                        h2 {
                            &.card-title {
                                font-size: 2.25rem;
                            }
                        }
                    }

                    // テキストブックが並ぶ場所
                    &.card-body {
                        div {
                            // テキストブックの一つの大きさ
                            &.card {
                                width: 180px;
                                height: 240px;
                                margin: 20px auto;
                                padding: 5%;

                                div {
                                    // カテゴリの表示
                                    &.card-header {
                                        min-width: 80%;
                                        height: 20%;
                                        margin-top: 5%;
                                        margin-bottom: 5%;
                                        margin-right: auto;
                                        margin-left: auto;
                                        padding: 3%;

                                        h3 {
                                            &.card-title {
                                                border-radius: 2px;
                                                display: inline-block;
                                                padding: 2px;
                                                width: 100%;
                                                font-size: 0.8rem;
                                                margin: 0 auto;
                                                box-shadow: 0px 2px 4px
                                                    rgba(0, 0, 0, 0.2);
                                            }
                                        }
                                    }

                                    // テキスト名の表示
                                    &.card-body {
                                        padding: 1%;
                                        height: 45%;
                                        max-height: 45%;
                                        font-weight: bold;
                                        font-size: 1.1rem;
                                    }

                                    &.card-footer {
                                        margin: 1% auto;
                                        height: 10%;

                                        // ダイヤモンドの大きさ
                                        img {
                                            width: 50px;
                                            filter: drop-shadow(
                                                1px 1px 1px rgba(0, 0, 0, 0.5)
                                            );
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        // テキストブック管理画面
        &.card-textbook {
            div {
                &.card-body {
                    div {
                        &.card {
                            // 横300、縦400
                            width: 270px;
                            height: 360px;
                            margin: 20px auto;
                            padding: 5%;

                            div {
                                &.card-header {
                                    width: 80%;
                                    height: 20%;
                                    margin-top: 5%;
                                    margin-bottom: 5%;
                                    margin-right: auto;
                                    margin-left: auto;
                                    padding: 3%;

                                    h3 {
                                        &.card-title {
                                            border: 0.5px solid $black;
                                            border-radius: 2px;
                                            display: inline-block;
                                            width: 100%;
                                            font-size: 0.8rem;
                                            margin: 0 auto;
                                            line-height: 30px;
                                        }
                                    }
                                }

                                // テキスト名の表示
                                &.card-body {
                                    padding: 1%;
                                    height: 45%;
                                    max-height: 45%;
                                    font-weight: bold;
                                    font-size: 1.2rem;
                                }

                                &.card-footer {
                                    margin: 1% auto;
                                    height: 10%;
                                    img {
                                        width: 50px;
                                        filter: drop-shadow(
                                            1px 1px 1px rgba(0, 0, 0, 0.5)
                                        );
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// スマホサイズ、テキストブックのデザイン
@media (max-width: 1199px) and (min-width: 768px) {
    // テキストブックのデザイン
    div {
        &.card {
            &.card-course {
                div {
                    &.card-header {
                        h2 {
                            &.card-title {
                                font-size: 1.5rem;
                            }
                        }
                    }

                    &.card-body {
                        div {
                            &.card {
                                width: 150px;
                                height: 200px;
                                margin: 15px auto;
                                padding: 5%;
                                background-size: contain;
                                div {
                                    &.card-header {
                                        height: 20%;
                                        width: 100%;
                                        margin-top: 7%;
                                        margin-right: auto;
                                        margin-left: auto;
                                        padding: 3%;

                                        h3 {
                                            &.card-title {
                                                border-radius: 2px;
                                                display: inline-block;
                                                padding: 2px;
                                                width: 100%;
                                                font-size: 0.8rem;
                                                margin: 0 auto;
                                                line-height: 25px;
                                                img {
                                                    height:15px;
                                                    width: 15px;
                                                    margin-bottom: 3px;
                                                }
                                            }
                                        }
                                    }

                                    // テキスト名の表示
                                    &.card-body {
                                        font-size: 1rem;
                                        padding: 5%;
                                        height: 40%;
                                        max-height: 40%;
                                        font-weight: bold;
                                    }

                                    &.card-footer {
                                        margin: 10% auto;
                                        padding-top: 5%;
                                        padding-bottom: 5%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        // テキストブック閲覧画面
        &.card-textbook {
            div {
                &.card-body {
                    div {
                        &.card {
                            width: 210px;
                            height: 280px;
                            margin: 10px auto;
                            padding: 5%;

                            div {
                                &.card-header {
                                    width: 80%;
                                    height: 20%;
                                    margin-top: 5%;
                                    margin-bottom: 5%;
                                    margin-right: auto;
                                    margin-left: auto;
                                    padding: 3%;

                                    h3 {
                                        &.card-title {
                                            border: 0.5px solid $black;
                                            border-radius: 2px;
                                            display: inline-block;
                                            width: 100%;
                                            font-size: 0.8rem;
                                            margin: 0 auto;
                                            line-height: 30px;
                                        }
                                    }
                                }

                                // テキスト名の表示
                                &.card-body {
                                    font-size: 1rem;
                                    padding: 1%;
                                    height: 40%;
                                    max-height: 40%;
                                    font-weight: bold;
                                }

                                &.card-footer {
                                    margin: 1% auto;
                                    height: 20%;

                                    img {
                                        width: 50px;                                                                                
                                        filter: drop-shadow(
                                            1px 1px 1px rgba(0, 0, 0, 0.5)
                                        );
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// スマホサイズ、テキストブックのデザイン
@media (max-width: 767px) {
    // テキストブックのデザイン
    div {
        &.card {
            &.card-course {
                div {
                    &.card-header {
                        h2 {
                            &.card-title {
                                font-size: 1.5rem;
                            }
                        }
                    }

                    &.card-body {
                        div {
                            &.card {
                                width: 180px;
                                height: 240px;
                                margin: 15px auto;
                                padding: 5%;

                                div {
                                    &.card-header {
                                        width: 100%;
                                        height: 20%;
                                        margin-top: 5%;
                                        margin-bottom: 5%;
                                        margin-right: auto;
                                        margin-left: auto;
                                        padding: 3%;

                                        h3 {
                                            &.card-title {
                                                border-radius: 2px;
                                                display: inline-block;
                                                padding: 2px;
                                                width: 100%;
                                                font-size: 0.8rem;
                                                margin: 0 auto;
                                                line-height: 25px;
                                            }
                                        }
                                    }

                                    // テキスト名の表示
                                    &.card-body {
                                        font-size: 1rem;
                                        padding: 6%;
                                        height: 40%;
                                        max-height: 40%;
                                        font-weight: bold;
                                    }

                                    &.card-footer {
                                        margin: 1% auto;
                                        height: 20%;

                                        // ダイヤモンドの画像
                                        img {
                                            width: 50px;
                                            height: 55px;
                                            filter: drop-shadow(
                                                1px 1px 1px rgba(0, 0, 0, 0.5)
                                            );
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        // テキストブック閲覧画面
        &.card-textbook {
            div {
                &.card-body {
                    div {
                        &.card {
                            width: 210px;
                            height: 280px;
                            margin: 10px auto;
                            padding: 5%;

                            div {
                                &.card-header {
                                    width: 80%;
                                    height: 20%;
                                    margin-top: 5%;
                                    margin-bottom: 5%;
                                    margin-right: auto;
                                    margin-left: auto;
                                    padding: 3%;

                                    h3 {
                                        &.card-title {
                                            border: 0.5px solid $black;
                                            border-radius: 2px;
                                            display: inline-block;
                                            width: 100%;
                                            font-size: 0.8rem;
                                            margin: 0 auto;
                                            line-height: 30px;
                                        }
                                    }
                                }

                                // テキスト名の表示
                                &.card-body {
                                    font-size: 1rem;
                                    padding: 1%;
                                    height: 40%;
                                    max-height: 40%;
                                    font-weight: bold;
                                }

                                &.card-footer {
                                    margin: 1% auto;
                                    height: 20%;

                                    img {
                                        width: 50px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
